import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeaderBannerAboutPg } from "../components/About-Page/header-banner-about"
import { AboutSectionAboutPg } from "../components/About-Page/about-aboutPg"
import { AboutStoryAboutPg } from "../components/About-Page/about-story-aboutPg"
import { MetricsSectionHomePg } from "../components/Home-Page/metrics-section-homePg"
import { CoreValuesSectionHomePg } from "../components/Home-Page/core-values-section-homePg"

const AboutPage = (props) => {
    return (
        <Layout>
            <Seo
                title="About Kaylar Construction: Experts in Kitchen Remodeling, Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, and Interior Design Services"
                description="Discover our expert general contracting services for kitchen/bathroom remodeling, siding, windows & doors, decks & patios, and interior design. Trust our skilled team to transform your space with exceptional craftsmanship and personalized service."
                location={props.location.pathname}
                keywords={['General contracting services', 'Kaylar Construction']}
            />
            <div>
                <HeaderBannerAboutPg />
                <AboutSectionAboutPg />
                <AboutStoryAboutPg />
                <MetricsSectionHomePg />
                <CoreValuesSectionHomePg />
            </div>
        </Layout>
    )
}
export default AboutPage