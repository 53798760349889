import React, { Component } from "react"
import aboutImage1 from '../../images/about-1.jpeg'
import aboutImage2 from '../../images/about-2.jpeg'

export class AboutStoryAboutPg extends Component {
    render() {
        return (
            <section className="about-info bg-gray py-12 md:py-16">
                <div className="container mx-auto">
                    <div className="about-info">
                        <div className="flex flex-wrap">
                            <div className="md:w-2/5 pr-4 pl-4">
                                <div className="about-info-img mb-60 hidden md:flex">
                                    <div className="img"> <img src={aboutImage1} className="img-fluid" title='Kaylar Construction' alt='Kaylar Construction' /> </div>
                                </div>
                                <div className="section-title2"><span>Kaylar Construction</span> Is Here For You!</div>
                                <p>
                                    At Kaylar Construction, we are here for you every step of the way. Our dedicated team is committed to providing personalized service, ensuring your needs and expectations are met with precision and care. 
                                    Whether you're planning a new project or need assistance with ongoing construction, we offer reliable support and expert guidance to bring your vision to reality. Your satisfaction is our priority, and we 
                                    strive to make the construction process seamless and stress-free for you
                                </p>
                                <div className="about-info-img md:hidden pt-5">
                                    <div className="img"> <img src={aboutImage1} className="img-fluid" title='Kaylar Construction Story' alt='Kaylar Construction Story' /> </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 pr-4 pl-4 offset-md-1">
                                <div className="section-title2">Leading The Way In <span>General Contracting!</span></div>
                                <p>We deliver exceptional quality, innovative solutions, and unmatched customer service. With a team of highly skilled professionals, we specialize in comprehensive construction
                                    services that cater to both residential and commercial projects. Our commitment to excellence ensures that every project is completed on time, within budget, and to the highest standards of craftsmanship. From initial planning and design to
                                    final execution, Kaylar Construction integrates cutting-edge technology and sustainable practices to create structures that stand the test of time. Trust us to bring your vision to life with unparalleled expertise and dedication.</p>
                                <div className="about-info-img pt-5 w-[30rem] mx-auto hidden md:flex">
                                    <div className="img"> <img src={aboutImage2} className="img-fluid" title='Kaylar Construction Story' alt='Kaylar Construction Story' /> </div>
                                </div>
                                <div className="about-info-img pt-5 mx-auto md:hidden">
                                    <div className="img"> <img src={aboutImage2} className="img-fluid" title='Kaylar Construction' alt='Kaylar Construction' /> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}